<template>
  <!-- 动火证 -->
  <div>
    <div class="title">申请信息：</div>
    <div class="box-row">
      <van-row class="row">
        <van-col class="row-col" span="14">
          <span class="label">监 护 人:</span>
          <van-field class="input" v-model="guardian.name" />
        </van-col>
      </van-row>
      <van-row class="row">
        <van-col class="row-col" span="16">
          <span class="label">联系电话:</span>
          <van-field class="input" type="tel" maxlength="11" v-model="guardian.mobile" />
        </van-col>
      </van-row>
    </div>
    <div class="box-row">
      <van-row class="row">
        <van-col class="row-col" span="14">
          <span class="label">现场负责人:</span>
          <van-field class="input" v-model="scene.name" />
        </van-col>
      </van-row>
      <van-row class="row">
        <van-col class="row-col" span="16">
          <span class="label">联系电话:</span>
          <van-field class="input" type="tel" maxlength="11" v-model="scene.mobile" />
        </van-col>
      </van-row>
    </div>
    <div class="box-row">
      <van-row class="row">
        <van-col class="row-col" span="14">
          <span class="label">单位负责人:</span>
          <van-field class="input" v-model="unit.name" />
        </van-col>
      </van-row>
      <van-row class="row">
        <van-col class="row-col" span="16">
          <span class="label">联系电话:</span>
          <van-field class="input" type="tel" maxlength="11" v-model="unit.mobile" />
        </van-col>
      </van-row>
    </div>
    <div class="box-row">
      <van-row class="row">
        <van-col class="row-col" span="14">
          <span class="label">动 火 人:</span>
          <van-field class="input" v-model="applicant.name" />
        </van-col>
      </van-row>
      <van-row class="row">
        <van-col class="row-col" span="16">
          <span class="label">联系电话:</span>
          <van-field class="input" type="tel" maxlength="11" v-model="applicant.mobile" />
        </van-col>
      </van-row>
       <div class="item-pics">
        <div class="pic">
          <van-image
              v-if="applicant.id_img"
              class="pic-img"
              :src="applicant.id_img"
              fit="cover"
              radius="4px"
          />
          <template v-else>
            <van-icon class="id-card" class-prefix="chame" name="shenfenzhengzhengmian" />
            <p>证件正面</p>
          </template>
          <my-upload
            :max-count="1"
            @up-loader="uploader($event, 'id_img')"
          />
        </div>
        <div class="pic">
          <van-image
              v-if="applicant.personal_photo"
              class="pic-img"
              :src="applicant.personal_photo"
              fit="cover"
              radius="4px"
          />
          <template v-else>
            <van-image class="id-card" :src="require('@/assets/imgs/man.png')" />
            <p>证件反面</p>
          </template>
          <my-upload
            :max-count="1"
            @up-loader="uploader($event, 'personal_photo')"
          />
        </div>
      </div>
    </div>
    <van-row class="row">
      <van-col class="row-col" span="18">
        <span class="label">动火日期:</span>
        <date-time-picker @confirm="selectedDate" />
      </van-col>
    </van-row>
    <van-row class="row">
      <van-col class="row-col" span="18">
        <span class="label">动火时间:</span>
        <my-times-picker style="flex: 1;" @confirm="selectedTime" />
      </van-col>
    </van-row>
    <van-row class="row">
      <van-col class="row-col" span="18">
        <span class="label">动火类型:</span>
        <div class="input">
          <my-picker :columns="fireTypeList" @confirm="selectedType"></my-picker>
        </div>
      </van-col>
    </van-row>
    <van-row class="row">
      <van-col class="row-col" span="12">
        <span class="label">灭火器个数:</span>
        <van-field class="input" type="digit" v-model="fire_ext_num" />
      </van-col>
    </van-row>
    <van-row class="row">
      <van-col class="row-col" span="24">
        <span class="label">动火单位:</span>
        <van-field class="input" v-model="company" />
      </van-col>
    </van-row>
    <van-row class="row">
      <van-col class="row-col" span="24">
        <span class="label">动火内容:</span>
        <van-field
          class="textarea"
          type="textarea"
          maxlength="100"
          show-word-limit
          v-model="content"
        />
      </van-col>
    </van-row>
    <van-row class="row">
      <van-col class="row-col" span="24">
        <span class="label">动火规范须知:</span>
        <div class="ul">
          <p>1、动火现场周围无易燃、易炸物质。</p>
          <p>2、动火管道、设备内有易燃、易炸气体、液体、沉淀物必须置换、清洗、并测炸分析合格。</p>
          <p>3、动火现场的临时用电必须符合“上海地区低压电器安全规程”的有关规定。</p>
          <p>4、落实其他必要的安全措施（防火毯、防火沙等）。</p>
        </div>
      </van-col>
    </van-row>
    <p class="notice">我已详细阅读动火规范须知，自愿承诺做好相关消防安全措施。</p>
    <div class="btns">
      <van-button type="primary" size="small" block round @click="onSubmit">提交申请</van-button>
    </div>
  </div>
</template>

<script>
import MyTimesPicker from '@/components/picker/times.vue'
import DateTimePicker from '@/components/picker/datetime.vue'
import MyPicker from '@/components/picker/index.vue'
import { postFireApi } from '@/api'
import { formatDate, isMobile } from '@/utils'
import MyUpload from '@/components/upload/index.vue'
export default {
  name: 'LicenseFire',
  components: {
    MyTimesPicker,
    DateTimePicker,
    MyPicker,
    MyUpload
  },
  data() {
    return {
      sh_info: {}, // 商户信息
      fireTypeList: [
        '电焊',
        '气焊、气割',
        '喷灯',
        '电炉',
        '电动工具'
      ], // 动火类型
      applicant: {
        name: '',
        mobile: '',
        id_img: '',
        personal_photo: ''
      }, // 动火人人信息
      guardian: {
        name: '',
        mobile: ''
      }, // 监护人信息
      scene: {
        name: '',
        mobile: ''
      }, // 现场施工负责人信息
      unit: {
        name: '',
        mobile: ''
      }, // 单位负责人
      date: '', // 动火日期
      time: '', // 动火时间
      fire_type: '', // 动火类型
      fire_ext_num: '', // 灭火器个数
      company: '', // 动火单位
      content: '', // 动火内容
    }
  },
  created() {
    if (!window.localStorage.getItem('sh_info')) return
    this.sh_info = JSON.parse(window.localStorage.getItem('sh_info'))
    if (!this.date) {
      this.date = formatDate()
    }
  },
  methods: {
    // 动火类型选择
    selectedType(type) {
      this.fire_type = type
    },
    // 动火日期选择
    selectedDate(date) {
      this.date = date
    },
    // 动火时间选择
    selectedTime(time) {
      this.time = time
    },
    // 证件上传
    uploader(images, name) {
      this.applicant[name] = images[0]
    },
    // 提交申请
    onSubmit() {
      if (
        !this.date ||
        !this.time ||
        !this.fire_type ||
        !this.fire_ext_num ||
        !this.company ||
        !this.applicant.name ||
        !this.applicant.mobile ||
        !this.applicant.id_img ||
        !this.applicant.personal_photo ||
        !this.guardian.name ||
        !this.guardian.mobile ||
        !this.scene.name ||
        !this.scene.mobile ||
        !this.unit.name ||
        !this.unit.mobile ||
        !this.content
      ) return this.$toast('请补全信息！')
      if (!isMobile(this.applicant.mobile)) return this.$toast('请填写正确的申请人手机号！')
      if (!isMobile(this.guardian.mobile)) return this.$toast('请填写正确的监护人手机号！')
      if (!isMobile(this.scene.mobile)) return this.$toast('请填写正确的现场负责人手机号！')
      if (!isMobile(this.unit.mobile)) return this.$toast('请填写正确的单位负责人手机号！')
      postFireApi({
        sid: this.sh_info.sid,
        encrypt: this.sh_info.encrypt,
        name: this.name,
        mobile: this.mobile,
        date: this.date,
        time: this.time,
        fire_type: this.fire_type,
        fire_ext_num: this.fire_ext_num,
        company: this.company,
        applicant: this.applicant,
        guardian: this.guardian,
        scene: this.scene,
        unit: this.unit,
        content: this.content
      }).then((res) => {
        console.log('动火证申请：', res)
        if (res.code !== 0) return
        this.$toast({
          type: 'success',
          message: '提交成功！',
          onClose: () => {
            this.$router.push('/license/list')
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 40px;
  font-size: 32px;
  font-weight: 500;
}
.box-row {
  margin-top: 20px;
  border: 1px solid #eee;
  padding: 0 0 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
/deep/ .row {
  margin-top: 20px;
  .row-col {
    display: flex;
    .label {
      line-height: 50px;
      width: 180px;
      font-size: 26px;
      color: #666;
      white-space: nowrap;
    }
    .input {
      flex: 1;
      height: 50px;
      padding: 0 10px;
      border: 1px solid #eee;
      border-radius: 8px;
      font-size: 24px;
    }
    .textarea {
      flex: 1;
      padding: 10px;
      border: 1px solid #eee;
      border-radius: 8px;
    }
    .ul {
      flex: 1;
      border: 1px solid #eee;
      padding: 10px;
      border-radius: 8px;
      p {
        font-size: 24px;
        margin-bottom: 5px;
        color: #666;
      }
    }
  }
}

.item-pics {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .pic {
      width: 240px;
      height: 151px;
      margin: 10px;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/ .pic-img {
        width: 100%;
        height: 100%;
      }
      .id-card {
        width: 240px;
        height: 151px;
        line-height: 151px;
        text-align: center;
        font-size: 150px;
        color: #6b6b6b;
      }
      /deep/ .van-uploader {
        z-index: 9;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .van-uploader__wrapper {
          width: 100%;
          height: 100%;
          .van-uploader__upload {
            width: 100%;
            height: 100%;
          }
        }
      }
      p {
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

.notice {
  margin-top: 20px;
  color: red;
  font-size: 24px;
}

.btns {
  margin: 60px 150px 40px;
}
</style>
