<template>
  <div>
    <van-field
      class="input"
      readonly
      v-model="timeVal"
      right-icon="clock-o"
      @click="isShow = true"
    />
    <van-popup v-model:show="isShow" position="bottom">
      <van-picker title="请选择出货时间段" :columns="columns" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'MyTimesPicker',
  data() {
    return {
      isShow: false,
      timeVal: '',
      columns: [
        {
          values: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
          defaultIndex: 7
        },
        {
          values: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
          defaultIndex: 0
        },
        {
          values: ['——'],
          defaultIndex: 0
        },
        {
          values: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
          defaultIndex: 8
        },
        {
          values: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
          defaultIndex: 0
        }
      ]
    }
  },
  methods: {
    onConfirm(value) {
      // console.log(value)
      const startTime = [value[0], value[1]].join(':')
      const endTime = [value[3], value[4]].join(':')
      this.timeVal = [startTime, endTime].join('-')
      this.isShow = false
      this.$emit('confirm', this.timeVal)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .input {
  height: 50px;
  padding: 0 10px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 24px;
}
</style>
